/* global _, ga */
/*
 * Digirow Webapp コントロールスクリプト
 *
 * スクリプトを動かすにはログイン済みクライアントのトークンが必要
 * ログインは loopback explorer から行うこと
 * メンバー情報はキャンペーントークンから取得するか、スクリプト内で新規に作成する
 *
 * スクリプト内でメンバーを作成する場合、メンバー情報は使い切りになり、共有・引き継ぎはできない
 *
 * スクリプト実行の成果物としてアクティビティが作成され、サーバーに保存される
 *
 * [HOW TO USE]
 *  -
 *
 * [対応済み質問タイプ]
 * 単一回答タイプ、複数回答タイプ、テキスト回答タイプ、リスト選択タイプ
 *
 */

import {config} from './config';
import PageUtil from './util';

var BASE_URL;

var pageUtil = new PageUtil();

// URLパラメーターから取得したキャンペーントークンを保持.
var campaignTokenFromUrl;
// 単一回答のボタンのクラス名をjQueryセレクタ形式で保持
var singleTypeAnswerButtons = '';
// 複数回答のボタンのクラス名をjQeryセレクタ形式で保持
var multipleTypeAnswerButtons = '';
// 単一回答のボタンと選択式回答
// ボタンのクラス名をjQueryセレクタ形式で保持
var singleTypeAndSelectTypeAnswerButtons = '';
// 単一回答のボタンとテキスト回答
// ボタンのクラス名をjQueryセレクタ形式で保持
var singleTypeAndTextTypeAnswerButtons = '';
// 複数回答のボタンとテキスト回答
// ボタンのクラス名をjQueryセレクタ形式で保持
var multipleTypeAndTextTypeAnswerButtons = '';

// 回答を保存するオブジェクト
var myAnswers = {};
// サーバーで作成された Activity のid
var activityId = null;
// SMS認証で使用した携帯電話番号
var smsPhoneNumber = null;
// 認証で獲得したDID
var myDid = null;

export default function() {
  // メディアクエリー セットアップ
  pageUtil.mediaQueryList = window.matchMedia('screen and (orientation: portrait) and (min-width: 768px)');
  pageUtil.mediaQueryList.addListener(checkMqlBreakPoint);

  // メディアクエリー実行
  checkMqlBreakPoint(pageUtil.mediaQueryList);

  // generate answer buttons string
  singleTypeAnswerButtons =
    PageUtil.pageNumbersToClasses(config.SINGLE_TYPE_ANSWER_PAGES);

  multipleTypeAnswerButtons =
    PageUtil.pageNumbersToClasses(config.MULTIPLE_TYPE_ANSWER_PAGES);

  singleTypeAndSelectTypeAnswerButtons =
    PageUtil.pageNumbersToClasses(config.SINGLE_TYPE_AND_SELECT_TYPE_ANSWER_PAGES);

  singleTypeAndTextTypeAnswerButtons =
    PageUtil.pageNumbersToClasses(config.SINGLE_TYPE_AND_TEXT_TYPE_ANSWER_PAGES);

  multipleTypeAndTextTypeAnswerButtons =
    PageUtil.pageNumbersToClasses(config.MULTIPLE_TYPE_AND_TEXT_TYPE_ANSWER_PAGES);

  // set configs
  if (window.location.hostname === 'localhost') {
    BASE_URL = config.local.BASE_URL;
  } else {
    BASE_URL = config.production.BASE_URL;
  }

  // 変数の初期化
  campaignTokenFromUrl = getParameterByName('t');
  console.debug('campaignTokenFromUrl: ' + campaignTokenFromUrl);
  console.debug('digirowId: ' + didFromCookie);

  if (config.CAMPAIGN_TOKEN_REQUIRED === true &&
    campaignTokenFromUrl === null) {
    // 不正アクセス対策
    // campaignTokenFromUrl がない場合は処理を中止
    return;
  }

  // selectを初期化
  $('select').material_select();

  // ランダマイズ
  //var children = $('#page-5 > .randomize').children();
  //PageUtil.shuffleArray(children);
  //$('#page-5 > .randomize').empty();
  //$('#page-5 > .randomize').append(children);

  // プログレス表示を初期化
  pageUtil.updateProgress(myAnswers);

  if (didFromCookie) {
    myDid = didFromCookie;
    // アクティビティ情報を取得する
    console.debug('get existing activity from server');
    // アクティビティ情報に合わせた画面を表示する
    getExistingActivity();
  } else {
    PageUtil.showStartPage();
  }

  // -------------
  // ハンドラ設定
  // -------------

  // 使用するボタン
  $('#use-btn').click(function() {
    updateActivity();
  });

  // はじめるボタン
  $('#start-btn').click(function() {
    // ページ遷移アニメーション
    // スタートページをスライドし、スライド終了後に最初の質問を表示する。
    PageUtil.animatePage($('#start-page'), -config.WINDOW_WIDTH, function() {
      $(this).addClass('hide');
      if (myDid) {
        // DID取得済みの場合は認証をスキップ
        // TODO オプトインをチェック
        $('#my-survey').removeClass('hide');
      } else {
        $('#auth-start-page').removeClass('hide');
      }
      window.scrollTo(0, 0);
    });
  });

 // 認証開始ボタン
 $('#auth-start-btn').click(function() {
   // ページ遷移アニメーション
   PageUtil.animatePage($('#auth-start-page'), -config.WINDOW_WIDTH, function() {
     $(this).addClass('hide');
     $('#auth-sms-page').removeClass('hide');
     window.scrollTo(0, 0);
    });
  });

 // 認証SMSボタン
 $('#auth-sms-btn').click(function() {
   $(this).addClass('disabled');
   submitPhoneNumber();
 });

 // 認証コードボタン
 $('#auth-code-btn').click(function() {
   $(this).addClass('disabled');
   $('#auth-code-input-field').addClass('hide');
   $('#auth-code-input-loader').removeClass('hide');
   submitAuthCode();
 });

 // 認証アンケート開始ボタン
 $('#auth-ok-btn').click(function() {
   // 既存のアクティビティがあるかどうかで遷移先を変える
   if (activityId) {
     // サンクス画面を表示
     PageUtil.animatePage($('#auth-ok-page'), -config.WINDOW_WIDTH, function() {
       $(this).addClass('hide');
       $('#thanks-page').removeClass('hide');
       window.scrollTo(0, 0);
     });
   } else {
    // サーベイ画面を表示
    PageUtil.animatePage($('#auth-ok-page'), -config.WINDOW_WIDTH, function() {
      $(this).addClass('hide');
      $('#my-survey').removeClass('hide');
      window.scrollTo(0, 0);
    });
   }
 });

  // 次へボタン
  $('#next-btn').click(function() {
    var nextBtn = this;
    var currentPageNumber = pageUtil.getCurrentPageNumber();
    var nextPageNumber = currentPageNumber + 1;
    if ($(nextBtn).hasClass('disabled')) {
      return;
    }

    // バリデーション
    console.debug('next-btn: currentPageNumber ' + currentPageNumber);
    if (!pageUtil.doValidation(currentPageNumber, myAnswers)) {
      console.debug('validation failed.');
      return;
    }

    // 連続クリック防止
    if ($(nextBtn).hasClass('clicked')) {
      return;
    }
    $(nextBtn).addClass('clicked');

    // 次へボタンの点滅を制御
    if ($(nextBtn).hasClass('blinking')) {
      pageUtil.stopNextBtnBlinking();
    }

    // フリーワード回答を保存
    var myText = '';
    config.TEXT_TYPE_ANSWER_PAGES.forEach(function(page) {
      myText = $('#page-' + page + '-input').val();
      if (myText !== '') {
        myAnswers[page] = myText;
      }
    });
    config.SINGLE_TYPE_AND_TEXT_TYPE_ANSWER_PAGES.forEach(function(page) {
      myText = $('#page-' + page + '-input').val();
      if (myText !== '') {
        myAnswers[page] = myText;
      }
    });

    setTimeout(function() {
      pageUtil.showNextPage(myAnswers);
      $(nextBtn).removeClass('clicked');
      // テキスト入力フォーカス制御
      // ページ遷移アニメーション完了後に実行
      setTimeout(function() {
        // フォーカスは Android のみ
        if (pageUtil.isTextTypeAnswerPage(nextPageNumber)) {
          $('#page-' + nextPageNumber + '-input').focus();
        }

        if (nextPageNumber === pageUtil.TOTAL_PAGES) {
          console.debug('this is the last page!');
          pageUtil.showNextPage(myAnswers);
          pageUtil.updateProgress(myAnswers);
        } else {
          pageUtil.startNextBtnBlinking();
        }
      }, 800);
    }, 300);
    pageUtil.updateProgress(myAnswers);
  });

  // 戻るボタン
  $('#prev-btn').click(function() {
    var currentPageNumber = pageUtil.getCurrentPageNumber();

    // 次へボタンの制御
    if ($('#next-btn').hasClass('disabled')) {
      $('#next-btn').removeClass('disabled');
    }

    if (currentPageNumber >= 2) {
      pageUtil.showPreviousPage();
    } else {
      // スタートページを表示
      console.debug('show startpage.');
      // ページ遷移アニメーション
      PageUtil.animatePage($('#my-survey'), config.WINDOW_WIDTH, function() {
        $('#start-page').css('left', 0);
        $('#start-page').removeClass('hide');
        $('#my-survey').addClass('hide');
        $('#my-survey').css('left', 0);
        window.scrollTo(0, 0);
      });
    }
  });

  // 送信ボタン
  $('#submit-btn').click(function() {
    // 連続クリック防止策
    if ($(this).hasClass('clicked')) {
      return;
    }
    $(this).addClass('clicked');

    // フリーワード回答を保存
    var myText = '';
    config.SINGLE_TYPE_AND_TEXT_TYPE_ANSWER_PAGES.forEach(function(page) {
      myText = $('#page-' + page + '-input').val();
      if (myText !== '') {
        myAnswers[page] = myText;
      }
    });
    config.MULTIPLE_TYPE_AND_TEXT_TYPE_ANSWER_PAGES.forEach(function(page) {
      myText = $('#page-' + page + '-input').val();
      if (myAnswers[page] === undefined) {
        myAnswers[page] = [];
      }
      if (myText !== '') {
        myAnswers[page].push(myText);
      }
    });
    config.TEXT_TYPE_ANSWER_PAGES.forEach(function(page) {
      myText = $('#page-' + page + '-input').val();
      if (myText !== '') {
        myAnswers[page] = myText;
      }
    });
    // 送付先
    myText = $('#page-1_1-input').val();
    myAnswers['1_1'] = myText;
    myText = $('#page-1_2-input').val();
    myAnswers['1_2'] = myText;
    myText = $('#page-1_3-input').val();
    myAnswers['1_3'] = myText;
    myText = $('#page-1_4-input').val();
    myAnswers['1_4'] = myText;
    myText = $('#page-1_5-input').val();
    myAnswers['1_5'] = myText;

    // サーベイ画面を隠す
    $('#my-survey').addClass('hide');

    // 送信中メッセージを表示
    $('#sending-page').removeClass('hide');

    // 抽選中画面の表示時間を設定
    setTimeout(function() {
      submitAnswers();
    }, 2000);
  });

  // -------------
  // アンケートハンドラ設定
  // -------------
  // 排他ボタン
  // 排他ボタン処理はMAを想定
  // 排他機能を有効にするには、排他ボタンがあるページのボタンに
  // 次のクラスを追加すること.
  // .exclusive-on: 排他ボタン、 .exclusive-out: 通常ボタン
  $('.exclusive-out').click(function() {
    // 同じページの exclusive-on が選択されている場合はチェックをはずす
    var currentPageNumber = pageUtil.getCurrentPageNumber();
    var exclusiveButtons = $('.page-' + currentPageNumber + '-btn.exclusive-on');
    var answer = null;

    console.debug('exclusive-out clicked on page: ' + currentPageNumber);
    PageUtil.resetButton(exclusiveButtons);
    exclusiveButtons.each(function(i, elem) {
      answer = $(elem).attr('id').replace('page-' + currentPageNumber + '-', '');
      myAnswers[currentPageNumber] = _.without(myAnswers[currentPageNumber], answer);
    });
  });

  $('.exclusive-on').click(function() {
    // 同じページの自分以外のボタンが選択されている場合はチェックをはずす
    var currentPageNumber = pageUtil.getCurrentPageNumber();
    var allButtons = $('.page-' + currentPageNumber + '-btn');
    var myButton = this;
    var answer = null;

    allButtons.each(function(i, elem) {
      if ($(elem).attr('id') !== $(myButton).attr('id')) {
        PageUtil.resetButton(elem);
        answer = $(elem).attr('id').replace('page-' + currentPageNumber + '-', '');
        myAnswers[currentPageNumber] = _.without(myAnswers[currentPageNumber], answer);
      }
    });
  });
  // -- 排他ボタン end

  // ボタン式アンケート回答: 単一選択、複数選択不可
  $(singleTypeAnswerButtons).click(function() {
    var currentPageNumber = pageUtil.getCurrentPageNumber();
    var myButton = this;
    console.debug('singleTypeAnswerInput page: ' + currentPageNumber);

    // 連続クリック防止策
    if ($(myButton).hasClass('clicked')) {
      return;
    }
    $(myButton).addClass('clicked');

    // ボタンのリセット
    PageUtil.resetAllButton(currentPageNumber);

    // 回答を保存
    myAnswers[currentPageNumber] = $(myButton).attr('id').replace('page-' + currentPageNumber + '-', '');

    // 選択状態にする
    pageUtil.setButtonSelected(myButton);

    // ページ遷移
    PageUtil.blink(myButton, function() {
      setTimeout(function() {
        // 連続クリック防止策
        $(myButton).removeClass('clicked');
        if (currentPageNumber === pageUtil.TOTAL_PAGES) {
          pageUtil.showSubmitBtn();
        }
      }, 800);
    });

    // プログレスバー更新
    pageUtil.updateProgress(myAnswers);
  });

  // ボタン式アンケート回答: 複数選択可
  $(multipleTypeAnswerButtons).click(function() {
    var currentPageNumber = pageUtil.getCurrentPageNumber();
    var myButton = this;
    console.debug('multipleTypeAnswerInput page: ' + currentPageNumber);

    var myAnswer = $(myButton).attr('id').replace('page-' + currentPageNumber + '-', '');

    if ($(myButton).hasClass('selected')) {
      // 選択を解除する
      PageUtil.resetButton(myButton);
      // 要素を削除
      myAnswers[currentPageNumber] = _.without(myAnswers[currentPageNumber], myAnswer);
    } else {
      // 選択状態にする
      pageUtil.setButtonSelected(myButton);
      PageUtil.blink(myButton, null);

      // 回答を保存
      if (myAnswers[currentPageNumber] === undefined) {
        myAnswers[currentPageNumber] = [];
      }
      myAnswers[currentPageNumber].push(myAnswer);
    }

    // プログレスバー更新
    pageUtil.updateProgress(myAnswers);
  });

  // 単一ボタン&プルダウン式アンケート回答
  $(singleTypeAndSelectTypeAnswerButtons).click(function() {
    var currentPageNumber = pageUtil.getCurrentPageNumber();
    var myButton = this;
    console.debug('singleTypeAndSelectTypeAnswerInput page: ' + currentPageNumber);

    // ボタンのリセット
    PageUtil.resetAllButton(currentPageNumber);
    // プルダウンを非表示にする
    $('.select-input-field').addClass('hide');

    // 回答を保存
    myAnswers[currentPageNumber] = $(myButton).attr('id').replace('page-' + currentPageNumber + '-', '');

    // 選択状態にする
    pageUtil.setButtonSelected(myButton);

    // 選択肢を表示するボタンか判断
    var matchedClass = $(myButton).attr('class').match(/select-.*-btn/);
    if (matchedClass !== null && matchedClass.length > 0) {
      // 選択肢を表示
      var selectIdSub = matchedClass[0].replace('select-', '').replace('-btn', '');
      PageUtil.blink(myButton, function() {
        setTimeout(function() {
          $('#page-' + selectIdSub + '-select-field').removeClass('hide');
        }, 800);
      });

      // プルダウンにハンドラを追加
      $('#page-' + selectIdSub + '-select-field').change(function() {
        // 回答を保存
        myAnswers[currentPageNumber] = $('#page-' + selectIdSub + '-select').val().replace('page-' + currentPageNumber + '-', '');
        // ページ遷移
        PageUtil.blink($('#page-' + selectIdSub + '-select-field select'), function() {
          setTimeout(function() {
            pageUtil.showNextPage(myAnswers);
          }, 800);
        });
        // プログレスバー更新
        pageUtil.updateProgress(myAnswers);
      });
    } else {
      // 連続クリック防止策
      if ($(myButton).hasClass('clicked')) {
        return;
      }
      $(myButton).addClass('clicked');

      PageUtil.blink(myButton, function() {
        setTimeout(function() {
          $(myButton).removeClass('clicked');
        }, 800);
      });
    }

    // プログレスバー更新
    pageUtil.updateProgress(myAnswers);
  });

  // 単一選択ボタンとテキスト回答
  $(singleTypeAndTextTypeAnswerButtons).click(function() {
    // テキスト回答は送信時に取得して answered に入れる
    // 現状 multipleTypeAnswerButtons と同じ処理
    var currentPageNumber = pageUtil.getCurrentPageNumber();
    var myButton = this;
    console.debug('singleTypeAndTextTypeAnswerInput page: ' + currentPageNumber);

    // 連続クリック防止策
    if ($(myButton).hasClass('clicked')) {
      return;
    }
    $(myButton).addClass('clicked');

    // ボタンのリセット
    PageUtil.resetAllButton(currentPageNumber);

    // 回答を保存
    myAnswers[currentPageNumber] = $(myButton).attr('id').replace('page-' + currentPageNumber + '-', '');

    // 選択状態にする
    pageUtil.setButtonSelected(myButton);

    // ページ遷移
    PageUtil.blink(myButton, function() {
      setTimeout(function() {
        // 連続クリック防止策
        $(myButton).removeClass('clicked');
      }, 800);
    });

    // プログレスバー更新
    pageUtil.updateProgress(myAnswers);
  });

  // 複数選択ボタンとテキスト回答
  $(multipleTypeAndTextTypeAnswerButtons).click(function() {
    // テキスト回答は送信時に取得して answered に入れる
    // 現状 multipleTypeAnswerButtons と同じ処理
    var currentPageNumber = pageUtil.getCurrentPageNumber();
    console.debug('multipleTypeAnswerInput page: ' + currentPageNumber);

    var myAnswer = $(this).attr('id').replace('page-' + currentPageNumber + '-', '');

    // ボタンのリセット
    if ($(this).hasClass('selected')) {
      // 選択を解除する
      PageUtil.resetButton(this);
      // 要素を削除
      myAnswers[currentPageNumber] = _.without(myAnswers[currentPageNumber], myAnswer);
    } else {
      // 選択状態にする
      pageUtil.setButtonSelected(this);
      PageUtil.blink(this, null);

      // 回答を保存
      if (myAnswers[currentPageNumber] === undefined) {
        myAnswers[currentPageNumber] = [];
      }
      myAnswers[currentPageNumber].push(myAnswer);
    }

    // プログレスバー更新
    pageUtil.updateProgress(myAnswers);
  });
}

/**
 * URLパラメータを取得する
 * @param {string} name パラメータ名
 * @param {string} url URL
 * @return {string} param
 */
function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  var results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/* *********************************************************
 * データ通信処理
 * ********************************************************/

/**
 *  既存アクティビティを取得
 */
function getExistingActivity() {
  console.debug('getExistingActivity()');
  var myActivities = null;
  var myCouponUsed = false;
  $.ajax({
    type: 'GET',
    dataType: 'json',
    url: '/survey',
    data: {
      did: didFromCookie
    },
    success: function(data) {
      myActivities = data.activities;
      if (myActivities.length === 0) {
        console.debug('Activity not found.');
        PageUtil.showStartPage();
      } else if (myActivities.length > 0) {
        console.debug('Activity found: ' + JSON.stringify(myActivities[0], null, 4));
        activityId = myActivities[0].activityId;
        if (myActivities[0].lastModifiedAt) {
          myCouponUsed = true;
        }
        // サンクス画面を表示
        PageUtil.showThanksPage(activityId, myCouponUsed);
      }
    }
  });
}

/**
 * 携帯電話番号を送信する
 */
function submitPhoneNumber() {
  var postData = {};
  var myPhoneNumber = PageUtil.zenkaku2hankaku($('#sms-input').val());

  myPhoneNumber = myPhoneNumber.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi,'');
  if (myPhoneNumber.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
    smsPhoneNumber = '+81' + myPhoneNumber.substr(1);
    postData.phone_number = smsPhoneNumber;
    console.log('phone_number: ' + smsPhoneNumber);
    // show loader
    $('#sms-input-field').addClass('hide');
    $('#sms-input-loader').removeClass('hide');
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/sendSms',
      data: postData,
      success: function(data) {
        console.debug('sendSms result: ' + JSON.stringify(data, null, 4));
        $('#auth-sms-page').addClass('hide');
        $('#auth-code-page').removeClass('hide');
      },
      error: function(err) {
        console.debug('エラー: ' + err);
        // エラーページを表示
        $('#auth-sms-page').addClass('hide');
        $('#error-page').removeClass('hide');
      }
    });
  } else {
    console.log('Bad phone number.');
    $('#auth-sms-error').removeClass('hide');
    $('#auth-sms-btn').removeClass('disabled');
  }
}

/**
 * 認証コードを送信する
 */
function submitAuthCode() {
  var myActivities = null;
  var postData = {
    phone_number: smsPhoneNumber
  };

  var myAuthCode = PageUtil.zenkaku2hankaku($('#auth-code-input').val());
  if (myAuthCode.match(/[0-9]{4}/)) {
    postData.auth_code = myAuthCode;
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/verify',
      data: postData,
      success: function(data) {
        console.debug('verify result: ' + JSON.stringify(data, null, 4));
        myDid = data.did;
        // 既存のアクティビティが存在するかチェック
        // Cookieを消した場合など.
        $.ajax({
          type: 'GET',
          dataType: 'json',
          url: '/survey',
          data: {
            did: myDid
          },
          success: function(data) {
            myActivities = data.activities;
            if (myActivities.length === 0) {
              console.debug('Activity not found.');
            } else if (myActivities.length > 0) {
              console.debug('Activity found: ' + JSON.stringify(myActivities[0], null, 4));
              activityId = myActivities[0].activityId;
              if (myActivities[0].lastModifiedAt) {
                $('#used-mask').removeClass('hide');
              }
              $('#prize-number').html(activityId);
            }
            // チェック後に遷移
            // 既存のアクティビティがあってもなくても遷移する
            $('#auth-code-page').addClass('hide');
            $('#auth-ok-page').removeClass('hide');
          }
        });
      },
      error: function(jqXHR) {
        console.debug('エラー: ' + jqXHR);
        if (jqXHR.status === 404) {
          $('#auth-code-input-loader').addClass('hide');
          $('#auth-code-input-field').removeClass('hide');
          $('#auth-code-error').removeClass('hide');
          $('#auth-code-btn').removeClass('disabled');
        } else {
          // エラーページを表示
          $('#auth-code-page').addClass('hide');
          $('#error-page').removeClass('hide');
        }
      }
    });
  }
}

/**
 * 回答を送信する
 */
function submitAnswers() {
  // アクティビティを作成
  var postData = {
    did: myDid,
    dimension1: myDid,
    dimension2: smsPhoneNumber,
    createdAt: new Date()
  };

  // 回答送信準備
  postData.dimension3 = myAnswers['1_1'];
  postData.dimension4 = myAnswers['1_2'];
  postData.dimension5 = myAnswers['1_3'];
  postData.dimension6 = myAnswers['1_4'];
  postData.dimension7 = myAnswers['1_5'];
  postData.dimension8 = myAnswers['2'];
  postData.dimension9 = myAnswers['3'];
  postData.dimension10 = myAnswers['4'];
  postData.dimension11 = myAnswers['5'];
  postData.dimension12 = myAnswers['6'];
  postData.dimension13 = myAnswers['7'];
  postData.dimension14 = myAnswers['8'];
  postData.dimension15 = myAnswers['9'];
  postData.dimension16 = myAnswers['10'];
  postData.dimension17 = myAnswers['11'];
  postData.dimension18 = myAnswers['12'];
  postData.dimension19 = myAnswers['13'];

  $.ajax({
    type: 'POST',
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    url: '/survey',
    //data: postData,
    data: JSON.stringify(postData),
    success: function(data) {
      console.debug();
      console.debug('Activity created: ' + JSON.stringify(data, null, 4));

      activityId = data.activityId;
      console.debug('activityId: ' + activityId);

      $('#prize-number').html(activityId);

      $('#sending-page').addClass('hide');
      $('#thanks-page').removeClass('hide');
      // ga 送信
      ga('send', 'event', 'Activity', 'created');
    },
    error: function(err) {
      console.debug('エラー: ' + err);
      // エラーページを表示
      // エラーページはthanksページと外見上は変わらないようにする
      $('#sending-page').addClass('hide');
      $('#error-page').removeClass('hide');
      // ga 送信
      ga('send', 'exception', {
        exDescription: err.message,
        exFatal: false
      });
    }
  });
}

/**
 * 回答を送信する
 */
function updateActivity() {
  console.debug('activityId: ' + activityId);

  var postData = {
    activityId: activityId,
    lastModifiedAt: new Date()
  };

  console.debug('updateActivity() postData: ' + JSON.stringify(postData, null, 4));

  $.ajax({
    type: 'PUT',
    dataType: 'json',
    url: '/survey',
    data: postData,
    success: function(activity) {
      console.debug('Activity updated: ' + JSON.stringify(activity));
      $('#used-mask').removeClass('hide');
      ga('send', 'event', 'Activity', 'updated');
    },
    error: function(err) {
      console.debug('エラー: ' + err);
      // ga 送信
      ga('send', 'exception', {
        exDescription: err.message,
        exFatal: false
      });
    }
  });
}

function checkMqlBreakPoint(mql) {
  if (mql.matches) {
    // tablet or larger
    console.debug('tablet or larger detected.');
    $('#prev-btn').addClass('btn-large');
    $('#next-btn').addClass('btn-large');
  }
}

