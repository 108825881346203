import {config} from './config';

export default class PageUtil {
  constructor() {
    this.pageHistory = [];
    this.currentPage = $('#page-1');
    this.nextPage = $('#page-2');
    this.TOTAL_PAGES = $('#pages').children().length;
    this.WINDOW_WIDTH = $(window).width();
    this.nextBtnBlinkTimer = null;
    this.mediaQueryList = null;
    this.FOOTER_HEIGHT = 70;
    this.FOOTER_WITH_BUTTON_HEIGHT = 150;
  }

  startNextBtnBlinking() {
    var elm = $('#next-btn');
    if (this.nextBtnBlinkTimer === null) {
      elm.addClass('blinking');
      this.nextBtnBlinkTimer = setInterval(function() {
        elm.fadeOut(400, function() {
          elm.fadeIn(400);
        });
      }, 2000);
    }
  }

  stopNextBtnBlinking() {
    if (this.nextBtnBlinkTimer !== null) {
      clearInterval(this.nextBtnBlinkTimer);
      this.nextBtnBlinkTimer = null;
    }
  }

  /**
   * 現在のページのページ番号を取得する
   * @return {Integer} ページ番号
   */
  getCurrentPageNumber() {
    var arr = this.currentPage.attr('id').split('-');
    return parseInt(arr[1], 10);
  }

  /**
   * ページのすべてのボタンを非選択状態にする
   *
   * @param {Integre} number ページ番号
   */
  static resetAllButton(number) {
    $('.page-' + number + '-btn').removeClass('selected');
    $('.page-' + number + '-btn').children('i').remove();
  }

  /**
   * ボタンを非選択状態にする
   *
   * @param {Object} button ボタン
   */
  static resetButton(button) {
    $(button).removeClass('selected');
    $(button).children('i').remove();
  }

  /**
   * ボタンを選択状態にする
   *
   * @param {Object} button ボタン
   */
  setButtonSelected(button) {
    $(button).prepend('<i class="material-icons right">done</i>');
    $(button).addClass('selected');
  }

  /**
   * 前のページに遷移する
   */
  showPreviousPage() {
    console.debug('pageUtil.showPreviousPage()');
    // 送信ボタンを隠す
    this.hideSubmitBtn();
    // 1つ前のページを取得
    var previousPage = this.pageHistory.pop();

    if (previousPage !== undefined) {
      // hide currentPage
      PageUtil.animatePage(this.currentPage, this.WINDOW_WIDTH, function() {
        $(this).addClass('hide');
      });
      // show previousPage
      previousPage.css({left: '-' + config.WINDOW_WIDTH + 'px'});
      previousPage.removeClass('hide');
      PageUtil.animatePage(previousPage, 0, function() {
        window.scrollTo(0, 0);
      });
      // update vars
      this.nextPage = this.currentPage;
      this.currentPage = previousPage;

      var currentPageNumber = this.getCurrentPageNumber();
      var nextPageNumber = currentPageNumber + 1;
      console.debug('showPreviousPage() updated currentPageNumber: ' + currentPageNumber + ' nextPageNumber: ' + nextPageNumber);
      // フッター表示更新
      $('#current-number').html(currentPageNumber);
      // 次へボタンを表示
      PageUtil.showGoNextBtn();
    }
  }

  /**
   * 次のページに遷移する
   *
   * @param {Object} myAnswers 回答
   */
  showNextPage(myAnswers) {
    console.debug('pageUtil.showNextPage()');
    console.debug('nextPage: ' + this.nextPage);

    if (this.nextPage === null) {
      this.showSubmitBtn();
    }

    if (this.nextPage !== null) {
      // 送信ボタンを隠す
      this.hideSubmitBtn();

      // calculate nextPage
      var nextPageNumber;
      if (config.NEXT_PAGE_CALCULATOR[this.getCurrentPageNumber()] !== undefined) {
        nextPageNumber = config.NEXT_PAGE_CALCULATOR[this.getCurrentPageNumber()](myAnswers);
        if (nextPageNumber === null) {
          this.nextPage = null;
        } else {
          this.nextPage = $('#page-' + nextPageNumber);
        }
      }

      if (this.nextPage !== null) {
        // hide currentPage
        PageUtil.animatePage(this.currentPage, -this.WINDOW_WIDTH, function() {
          $(this).addClass('hide');
        });
        // show nextPage
        this.nextPage.css({left: this.WINDOW_WIDTH + 'px'});
        this.nextPage.removeClass('hide');
        PageUtil.animatePage(this.nextPage, 0, function() {
          window.scrollTo(0, 0);
        });
        // update vars
        this.pageHistory.push(this.currentPage);
        this.currentPage = this.nextPage;

        var currentPageNumber = this.getCurrentPageNumber();
        nextPageNumber = currentPageNumber + 1;
        console.debug('showNextPage() updated currentPageNumber: ' + currentPageNumber + ' nextPageNumber: ' + nextPageNumber);

        if (nextPageNumber > this.TOTAL_PAGES) {
          this.nextPage = null;
          // 次へボタンを制御
          $('#next-btn').addClass('disabled');
          // 最後の質問が回答済みならば送信ボタンを表示
          if (myAnswers[currentPageNumber] !== undefined) {
            this.showSubmitBtn();
          }
        } else {
          this.nextPage = $('#page-' + nextPageNumber.toString());
        }

        // フッター表示
        $('#current-number').html(currentPageNumber);

        // 次へボタン制御
        // 単一選択以外は次へボタンを表示
        if (config.SINGLE_TYPE_ANSWER_PAGES.indexOf(currentPageNumber) < 0) {
          if (config.MULTIPLE_TYPE_ANSWER_PAGES.indexOf(currentPageNumber) > -1) {
            // 複数回答のボタンのページでは回答済みの場合のみボタンを表示
            if (myAnswers[currentPageNumber] !== undefined) {
              PageUtil.showGoNextBtn();
            }
          } else if (config.SINGLE_TYPE_AND_SELECT_TYPE_ANSWER_PAGES.indexOf(currentPageNumber) > -1) {
            if (myAnswers[currentPageNumber] !== undefined) {
              // 単一回答のボタンと選択式回答のページでは回答済みの場合のみボタンを表示
              PageUtil.showGoNextBtn();
            }
          } else if (this.nextPage !== null) {
            // 次へボタンを表示
            PageUtil.showGoNextBtn();
          }
        }
      }
    }
  }

  /**
   * プログレス表示を更新する
   *
   * @param {Array} answers 回答
   * @return {Boolean} すべての質問に回答済みの場合はtrueを返す
   */
  updateProgress(answers) {
    var questions = this.TOTAL_PAGES;
    var answerCounter = 0;
    var remaining;
    var completed;

    console.debug('updateProgress: ' + JSON.stringify(answers));

    if (config.SHOW_PROGRESS_NUMBER === true) {
      // 問題数が変化しない場合、回答済みの問題数をプログレスバーに表示する
      for (var answer in answers) {
        if (answer) {
          answerCounter++;
        }
      }
      remaining = questions - answerCounter;
      $('#total-number').html(questions);
      $('#remaining-number').html(remaining);
    } else {
      // 問題数が変化する場合、現在のページをプログレスバーに表示する
      $('#current-label').hide();
      $('#remaining-label').hide();
      answerCounter = this.getCurrentPageNumber();
    }

    completed = Math.floor((answerCounter / questions) * 100);

    if (completed >= 100) {
      completed = 100;
    }

    // フッター表示
    $('#progress-indicator').width(completed + '%');

    return completed === 100;
  }

  /**
   * ページのアニメーション
   *
   * @param {object} target ターゲット要素
   * @param {integer} distance 距離(px)
   * @param {object} callback コールバック関数
   */
  static animatePage(target, distance, callback) {
    var value = (distance < 0 ? '-' : '') + Math.abs(distance).toString();
    target.animate({
      left: value + 'px'
    }, {
      duration: 250,
      complete: callback
    });
  }

  /**
   * 最後のアンケート画面かどうか
   *
   * @return {Boolean} 最後のアンケート画面の場合はtrueを返す
   */
  isLastPage() {
    return (this.nextPage === null);
  }

  /**
   * スタート画面を表示する
   * ※ 準備中や終了ページを表示する際には、この関数を修正すること
   */
  static showStartPage() {
    $('#loading-page').addClass('hide');
     $('#start-page').removeClass('hide');
    // $('#auth-ok-page').removeClass('hide');
    // $('#auth-code-page').removeClass('hide');
    // $('#auth-sms-page').removeClass('hide');
    // $('#auth-start-page').removeClass('hide');
    // $('#expired-page').removeClass('hide');
    // $('#wait-page').removeClass('hide');g
    // $('#thanks-page').removeClass('hide');
    // $('#error-page').removeClass('hide');
    // $('#sending-page').removeClass('hide');
  }

  /**
   * サンクス画面を表示する
   */
  static showThanksPage(activityId, isUsed) {
    $('#prize-number').html(activityId);
    if (isUsed) {
      $('#used-mask').removeClass('hide');
    }
    $('#loading-page').addClass('hide');
    $('#thanks-page').removeClass('hide');
  }

  /**
   * gonextボタンを表示する
   */
  static showGoNextBtn() {
    // $('#gonext-btn-wrapper').removeClass('hide');
    console.debug('showGoNext!!!');
  }

  /**
   * submitボタンを表示する
   */
  showSubmitBtn() {
    console.debug('showSubmitBtn()');
    $('#submit-btn-wrapper').removeClass('hide');
    this.adjustFooterHeight();
  }

  /**
   * submitボタンを隠す
   */
  hideSubmitBtn() {
    $('#submit-btn-wrapper').addClass('hide');
    this.adjustFooterHeight();
  }

  /**
   * footerの高さを調整する
   */
  adjustFooterHeight() {
    var footerHeight = 0;
    if ($('#submit-btn-wrapper').hasClass('hide')) {
      footerHeight = this.FOOTER_HEIGHT;
    } else {
      footerHeight = this.FOOTER_WITH_BUTTON_HEIGHT;
    }

    if (this.mediaQueryList.matches) {
       // tablet or larger
      footerHeight += 20;
    }

    $('#survey-footer').height(footerHeight + 'px');
  }

  /**
   * 点滅させる
   * @param {obj} obj 点滅対象
   * @param {function} cb コールバック関数
   */
  static blink(obj, cb) {
    if (cb === null) {
      $(obj)
        .fadeOut(200)
        .fadeIn(200)
        .fadeOut(200)
        .fadeIn(200);
    } else {
      $(obj)
        .fadeOut(200)
        .fadeIn(200)
        .fadeOut(200)
        .fadeIn(200, cb());
    }
  }

  /**
   * ページ番号の配列をjQuery形式のクラスセレクタ文字列
   * に変換する。ボタン用。
   * @param {Array} numbers ページ番号の配列
   * @return {String} jQuery形式のクラスセレクタ文字列
   */
  static pageNumbersToClasses(numbers) {
    var selectorString = '';
    numbers.forEach(function(page) {
      if (selectorString === '') {
        selectorString = '.page-' + page + '-btn';
      } else {
        selectorString += ', .page-' + page + '-btn';
      }
    });
    return selectorString;
  }

  /**
   * ページ番号の配列をjQuery形式のクラスセレクタ文字列
   * に変換する。input用。
   * @param {Array} numbers ページ番号の配列
   * @return {String} jQuery形式のクラスセレクタ文字列
   */
  static pageNumbersToInputClasses(numbers) {
    var selectorString = '';
    numbers.forEach(function(page) {
      if (selectorString === '') {
        selectorString = '.page-' + page + '-input';
      } else {
        selectorString += ', .page-' + page + '-input';
      }
    });
    return selectorString;
  }

  /**
   * テキスト回答のページの場合、true を返す
   * @param {Int} pageNumber 判別するページ番号
   * @return {Boolean} テキスト型の回答ページの場合 true
   */
  isTextTypeAnswerPage(pageNumber) {
    return (config.TEXT_TYPE_ANSWER_PAGES.indexOf(pageNumber) > -1);
  }

  /**
   * 複数回答のページの場合、true を返す
   * @param {Int} pageNumber 判別するページ番号
   * @return {Boolean} テキスト型の回答ページの場合 true
   */
  isMultipleTypeAnswerPage(pageNumber) {
    return (config.MULTIPLE_TYPE_ANSWER_PAGES.indexOf(pageNumber) > -1);
  }

  /**
   * 単一回答とテキスト回答のページの場合、true を返す
   * @param {Int} pageNumber 判別するページ番号
   * @return {Boolean} テキスト型の回答ページの場合 true
   */
  isSingleTypeAndTextTypeAnswerPages(pageNumber) {
    return (config.SINGLE_TYPE_AND_TEXT_TYPE_ANSWER_PAGES.indexOf(pageNumber) > -1);
  }

  /**
   * 複数回答とテキスト回答のページの場合、true を返す
   * @param {Int} pageNumber 判別するページ番号
   * @return {Boolean} テキスト型の回答ページの場合 true
   */
  isMultipleTypeAndTextTypeAnswerPage(pageNumber) {
    return (config.MULTIPLE_TYPE_AND_TEXT_TYPE_ANSWER_PAGES.indexOf(pageNumber) > -1);
  }

  /**
   * 入力値の全角を半角に置換
   * @param {String} val 入力値
   * @return {String} 全角を半角に置換した文字列
   */
  static zenkaku2hankaku(val) {
    var regex = /[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g;

    var value = val
      .replace(regex, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      })
      .replace(/[‐－―]/g, '-')
      .replace(/[～〜]/g, '~');

    return value;
  }

  /**
   * 配列の中身をシャッフルする
   * @param {Array} array 配列
   * @return {Array} シャッフルした配列
   */
  static shuffleArray(array) {
    var n = array.length;
    var t;
    var i;

    while (n) {
      i = Math.floor(Math.random() * n--);
      t = array[n];
      array[n] = array[i];
      array[i] = t;
    }
    return array;
  }

  /**
   * バリデーションを実行する
   * @param {Int} pageNumber 対象のページ番号
   * @param {Object} myAnswers 回答を保持するオブジェクト
   * @return {Boolean} 成功の場合は true
   */
  doValidation(pageNumber, myAnswers) {
    var errorMsg = '';
    var myTextInputVal = '';

    switch (pageNumber) {
      // テキスト入力
      case 1:
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numReg = /^[0-9]+$/;
        myTextInputVal = $('#page-1_1-input').val();
        if (myTextInputVal === '') {
          errorMsg = '下線部分をタップして、郵便番号を入力してください。';
        } else if (!numReg.test(String(myTextInputVal).toLowerCase())) {
          errorMsg = '郵便番号は半角数字のみで入力してください。';
        } else if (myTextInputVal.length !== 7) {
          errorMsg = '郵便番号は7文字で入力してください。';
        }
        myTextInputVal = $('#page-1_2-input').val();
        if (myTextInputVal === '') {
          errorMsg = '下線部分をタップして、住所を入力してください。';
        } else if (myTextInputVal !== '' && myTextInputVal.length > 200) {
          errorMsg = '住所は200文字以下で入力してください。';
        }
        myTextInputVal = $('#page-1_3-input').val();
        if (myTextInputVal === '') {
          errorMsg = '下線部分をタップして、氏名を入力してください。';
        } else if (myTextInputVal !== '' && myTextInputVal.length > 100) {
          errorMsg = '氏名は100文字以下で入力してください。';
        }
        myTextInputVal = $('#page-1_4-input').val();
        if (myTextInputVal === '') {
          errorMsg = '下線部分をタップして、電話番号をハイフンなしで入力してください。';
        } else if (!numReg.test(String(myTextInputVal).toLowerCase())) {
          errorMsg = '電話番号は半角数字のみで入力してください。';
        } else if (myTextInputVal.length !== 10 && myTextInputVal.length !== 11) {
          errorMsg = '電話番号は10桁または11桁で入力してください。';
        }
        myTextInputVal = $('#page-1_5-input').val();
        if (myTextInputVal === '') {
          errorMsg = '下線部分をタップして、メールアドレスを入力してください。';
        } else if (!re.test(String(myTextInputVal).toLowerCase())) {
          errorMsg = '正しい形式のメールアドレスを入力してください。';
        }
        break;
      // テキスト入力
      case 13:
        myTextInputVal = $('#page-13-input').val();
        if (myTextInputVal.length > 250) {
          errorMsg = 'ご意見・ご要望は250文字以内で入力してください。';
        }
        break;
      // SA
      case 6: 
      case 7: 
      case 8: 
      case 9: 
      case 10: 
      case 11: 
      case 12: 
        if (myAnswers[pageNumber] === undefined) {
          errorMsg = '回答を選択してください。';
        } else if (myAnswers[pageNumber] === null) {
          errorMsg = '回答を選択してください。';
        }
        break;
      // MA
      case 2:
      case 3:
      case 4:
      case 5:
        if (myAnswers[pageNumber] === undefined) {
          errorMsg = '回答を選択してください。';
        } else if (myAnswers[pageNumber] === null) {
          errorMsg = '回答を選択してください。';
        } else if (myAnswers[pageNumber].length === 0) {
          errorMsg = '回答を選択してください。';
        }
        break;
      default:
        errorMsg = '';
    }

    if (errorMsg !== '') {
      $.magnificPopup.open({
        items: {
          src: '<div class="white-popup">' + errorMsg + '</div>',
          type: 'inline'
        }
      });
      return false;
    }
    return true;
  }
}

