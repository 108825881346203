export var config = {
  local: {
    // WEBサーバーのURL
    BASE_URL: 'http://localhost:3000/'
  },
  production: {
    // WEBサーバーのURL
    BASE_URL: 'https://eg2024-api-prod-d048e2ee655f.herokuapp.com/'
  },
  // 回答数を数字で表示する. 問題の数が変化しない場合は true にする.
  SHOW_PROGRESS_NUMBER: false,
  // ウィンドウ幅. アニメーションで使用
  WINDOW_WIDTH: $(window).width(),
  // 質問画面のページ数
  TOTAL_PAGES: $('#pages').children().length,
  // トークンが必要なキャンペーンの場合は true
  CAMPAIGN_TOKEN_REQUIRED: false,
  // 単一回答のページ番号を指定
  SINGLE_TYPE_ANSWER_PAGES: [6, 7, 8, 9, 10, 11, 12],
  // 複数回答のページ番号を指定
  MULTIPLE_TYPE_ANSWER_PAGES: [2, 3, 4, 5],
  // 単一回答のボタンと選択式回答のページ番号を指定
  SINGLE_TYPE_AND_SELECT_TYPE_ANSWER_PAGES: [],
  // 単一回答のボタンとテキスト回答のページ番号を指定
  SINGLE_TYPE_AND_TEXT_TYPE_ANSWER_PAGES: [],
  // 複数回答のボタンとテキスト回答のページ番号を指定
  MULTIPLE_TYPE_AND_TEXT_TYPE_ANSWER_PAGES: [],
  // テキスト回答のページ番号を指定
  TEXT_TYPE_ANSWER_PAGES: [13],
  // 次のページの遷移先を指定する関数.
  // 関数が登録されていない場合は次のページへ遷移する.
  NEXT_PAGE_CALCULATOR: {
    14: function() {
      // 注意) 最後のページの場合は null を返すこと
      return null;
    }
  }
};
